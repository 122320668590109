import * as React from 'react';
import * as S from '@components/Quests/Quest.styled';
import { FC, memo } from 'react';
import { TTask } from '@src/app/types';
import { TaskCategory, setCurrentTask } from '@src/redux/slices/taskSlice';
import { getHumanBalance } from '@common/number';
import { useAppDispatch, useHapticFeedback } from '@common/hooks';
import SVG from '@ui/SVG/SVG';

type TProps = {
  task: TTask;
}

const QuestCard: FC<TProps> = ({ task }) => {
  const dispatch = useAppDispatch();
  const [impactOccurred] = useHapticFeedback();

  return (
    <S.Task
      onClick={() => {
        impactOccurred('soft');
        if (task.status !== TaskCategory.DONE) {
          dispatch(setCurrentTask(task));
        }
      }}
      $isDone={task.status === TaskCategory.DONE}
    >
      <img src={`https://api.avataria-nft.ru/images/task/360/${task.image}`} alt="" />
      <S.TaskContent>
        <S.TaskName>{task.name}</S.TaskName>
        <S.TaskDescription>{task.description}</S.TaskDescription>
        <S.TaskReward>
          <span>{getHumanBalance(task.reward)}</span>
          <SVG
            name="coin"
            width={12}
            height={12}
          />
        </S.TaskReward>
      </S.TaskContent>
      <S.TaskButton>
        Старт
      </S.TaskButton>
    </S.Task>
  );
};

export default memo(QuestCard);
