import { Link as BaseLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Footer = styled.div`
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    background-color: #0E0C14;
    border-radius: 10px;
    width: calc(100% - 20px);
    position: sticky;
    z-index: 100;
    bottom: 10px;
    left: 10px;
    
    .modal-open & {
        z-index: 0;
    }
`;

type TLink = {
  $isActive: boolean;
}

export const Link = styled(BaseLink)<TLink>`${({ $isActive }) => css`
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1;
    color: ${$isActive ? '#FFF' : 'rgba(255,255,255,0.7)'};
    background-color: ${$isActive ? '#2872EF' : 'transparent'};
    pointer-events: ${$isActive ? 'none' : 'auto'};
    border-radius: 10px;
    transition: color .2s ease-in-out, background-color .2s ease-in-out;
    
    img {
        transition: filter .2s ease-in-out;
        filter: grayscale(${$isActive ? '0' : '1'});
    }
`}`;
