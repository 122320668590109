import * as React from 'react';
import * as S from './Capital.styled';
import { FC, memo } from 'react';
import { useHapticFeedback } from '@common/hooks';
import LevelBar from '@components/LevelBar';
import PlayerBalance from '@components/PlayerBalance';

type TProps = {
  children: React.JSX.Element | React.JSX.Element[];
}

const Capital: FC<TProps> = ({ children }) => {
  const [impactOccurred] = useHapticFeedback();

  return (
    <S.Wrapper>
      <S.Header>
        <S.Top>
          <LevelBar />
          <S.NftButton to="/nft" onClick={() => impactOccurred('soft')}>
            My NFT Avatar
          </S.NftButton>
        </S.Top>
        <S.Grid>
          <PlayerBalance />
        </S.Grid>
      </S.Header>
      <S.Content>
        {children}
      </S.Content>
    </S.Wrapper>
  );
};

export default memo(Capital);
