import * as React from 'react';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { selectLastPassiveEarn } from '@src/redux/slices/playerSlice';
import { useModalOpen } from '@common/hooks';
import { useSelector } from 'react-redux';
import Button from '@ui/Button/Button';
import Modal from '@ui/Modal/Modal';
import EarnIcon from '@src/images/earn.png';
import SVG from "@ui/SVG/SVG";
import * as S from './EarnModal.styled';

const EarnModal: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const lastPassiveEarn = useSelector(selectLastPassiveEarn);
  useModalOpen(isOpen);

  useEffect(() => {
    if (lastPassiveEarn > 0) {
      setIsOpen(true);
    }
  }, [lastPassiveEarn]);

  const closeCallback = useCallback(() => {
    setIsOpen(false);
  }, []);

  return isOpen ? (
    <Modal
      title="Пасивный доход"
      closeCallback={closeCallback}
      imageSrc={EarnIcon}
    >
      <S.Text>Пока вас не было пассивный доход составил</S.Text>
      <S.Earn>
        <SVG name="coin" width={34} height={34} />
        <span>+{lastPassiveEarn}</span>
      </S.Earn>
      <Button
        text="Забрать"
        clickCallback={closeCallback}
      />
    </Modal>
  ) : null;
};

export default memo(EarnModal);
