import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en/default.json';
import ruTranslation from './ru/default.json';

i18next
  .use(initReactI18next)
  .init({
    lng: Telegram.WebApp.initDataUnsafe.user?.language_code || 'en',
    debug: true,
    resources: {
      en: {
        translation: enTranslation,
      },
      ru: {
        translation: ruTranslation,
      },
    },
  });
