import { TDeposit, TPlayer } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { sendRequest } from '@src/network';

type TDepositState = {
  deposits: TDeposit[];
  currentDeposit?: TDeposit;
  loading: boolean;
  investingDeposit: boolean;
}

export type TInvestDeposit = {
  deposits: TDeposit[];
  player: TPlayer;
}

export type TFetchDeposits = {
  deposits: TDeposit[];
}

export const depositSlice = createAppSlice({
  name: 'deposit',
  initialState: {
    deposits: [],
    loading: true,
    investingDeposit: false,
  } as TDepositState,
  reducers: create => ({
    setCurrentDeposit: create.reducer<TDeposit | undefined>((state, action) => {
      state.currentDeposit = action.payload;
    }),
    fetchDeposits: create.asyncThunk(
      async (_, { signal }) => sendRequest<TFetchDeposits>('/deposit', {
        signal,
      }),
      {
        pending: state => {
          state.loading = true;
        },
        fulfilled: (state, action) => {
          state.deposits = action.payload?.deposits || [];
          state.currentDeposit = action.payload?.deposits[0];
          state.loading = false;
        },
      },
    ),
    investDeposit: create.asyncThunk(
      async (payload: { id: number }, { signal }) => sendRequest<TInvestDeposit>('/deposit/invest', {
        signal,
        method: 'POST',
        body: {
          id: payload.id,
        },
        showToast: true,
      }),
      {
        pending: state => {
          state.investingDeposit = true;
        },
        rejected: state => {
          state.investingDeposit = false;
        },
        fulfilled: (state, action) => {
          state.deposits = action.payload?.deposits || [];
          state.currentDeposit = action.payload?.deposits.find(deposit => deposit.id === state.currentDeposit?.id);
          state.investingDeposit = false;
        },
      },
    ),
  }),
  selectors: {
    selectDeposits: state => state.deposits,
    selectCurrentDeposit: state => state.currentDeposit,
    selectDepositLoading: state => state.loading,
    selectInvestingDeposit: state => state.investingDeposit,
  },
});

export const {
  setCurrentDeposit,
  fetchDeposits,
  investDeposit,
} = depositSlice.actions;
export const {
  selectDeposits,
  selectCurrentDeposit,
  selectDepositLoading,
  selectInvestingDeposit,
} = depositSlice.selectors;

export default depositSlice.reducer;
