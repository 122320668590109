import { TPlayer } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { sendRequest } from '@src/network';

type TRatingSliceState = {
  players: TPlayer[];
  loading: boolean;
}

export const ratingSlice = createAppSlice({
  name: 'rating',
  initialState: {
    players: [],
    loading: true,
  } as TRatingSliceState,
  reducers: create => ({
    fetchPlayers: create.asyncThunk(
      async (_, { signal }) => sendRequest<TPlayer[]>(`/rating`, {
        signal,
      }),
      {
        pending: state => {
          state.loading = true;
          state.players = [];
        },
        rejected: (state, action) => {
          console.log(action.error);
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.players = action.payload || [];
        },
      },
    ),
  }),
  selectors: {
    selectPlayers: state => state.players,
    selectRatingLoading: state => state.loading,
  },
});

export const {
  fetchPlayers,
} = ratingSlice.actions;

export const {
  selectPlayers,
  selectRatingLoading,
} = ratingSlice.selectors;

export default ratingSlice.reducer;
