import styled, { css } from 'styled-components';

export const TapContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto 0 0;
`;

type TTapButton = {
  $isTaping: boolean;
}

export const TapButton = styled.button<TTapButton>`${({ $isTaping }) => css`
    display: flex;
    transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
    color: #fff;
    transform: ${$isTaping ? 'scale(.95)' : 'scale(1)'};
`}`;

export const TapFooter = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px 20px;
    background-color: rgba(255,255,255,0.1);
    border-radius: 20px;
    backdrop-filter: blur(20px);
    
    svg {
        position: relative;
    }
`;

type TTapFooterProgress = {
  $width: number;
}

export const TapFooterProgress = styled.div<TTapFooterProgress>`${({$width}) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${$width}%;
    height: 100%;
    background: linear-gradient(103.64deg, #EEBE14 -11.46%, #EE3814 183.02%);
    border-radius: 20px;
`}`;

export const EnergyText = styled.div`
    margin-left: 4px;
    font-size: 18px;
    font-weight: 500;
    position: relative;
`;

export const Animate = styled.div`
    pointer-events: none;
    user-select: none;

    div {
        position: fixed;
        z-index: 10;
        font-size: 60px;
        font-weight: 700;
        animation: appear 1s;

        @keyframes appear {
            0% {
                opacity: 1;
                transform: translate(0, 0) scale(1);
            }

            100% {
                opacity: 0;
                transform: translate(0, -8rem) scale(0.5);
            }
        }
    }
`;
