import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

export const Header = styled.div`
    padding: 10px;
`;

export const Top = styled.div`
    display: grid;
    grid-template-columns: 1fr 130px;
    gap: 50px;
    padding: 5px;
    backdrop-filter: blur(14px);
    background: #FFFFFF1A;
    border-radius: 10px;
    border: 1px solid rgba(255,255,255,0.4);
`;

export const Grid = styled.div`
    margin-top: 10px;
`;

export const NftButton = styled(NavLink)`
    width: 130px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 1;
    color: #fff;
    background: linear-gradient(103.59deg, #FFAD1B -2.48%, #DF4223 188.9%);
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 #418CFD80;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid rgba(255,255,255,0.4);
        border-radius: 10px;
        pointer-events: none;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 10px 10px 0;
    position: relative;
    z-index: 10;
`;
