import React, { FC, memo } from 'react';
import Capital from "@layouts/Capital";

const Profile: FC = () => (
  <Capital>
    <div>Профиль</div>
  </Capital>
);

export default memo(Profile);
