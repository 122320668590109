import * as S from '@components/Navigation/Navigation.styled';
import { useHapticFeedback } from '@common/hooks';
import { useLocation } from 'react-router-dom';
import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import GameIcon from '@src/images/game.png';
import ShopIcon from '@src/images/shop.png';
import ProfileIcon from '@src/images/profile.png';
import RatingIcon from '@src/images/rating.png';
import QuestIcon from '@src/images/quest.png';

const Navigation: FC = () => {
  const {t} = useTranslation();
  const [impactOccurred] = useHapticFeedback();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      Telegram.WebApp.BackButton.hide();
    } else {
      Telegram.WebApp.BackButton.show();
    }
  }, [location]);

  const isActive = (path: string) => location.pathname === path;

  return (
    <S.Footer>
      <S.Link
        onClick={() => impactOccurred('soft')}
        to="/"
        $isActive={isActive('/')}
      >
        <img
          src={GameIcon}
          alt=""
        />
        {t('game')}
      </S.Link>
      <S.Link
        onClick={() => impactOccurred('soft')}
        to="/quests"
        $isActive={isActive('/quests')}
      >
        <img
          src={QuestIcon}
          alt=""
        />
        {t('quests')}
      </S.Link>
      <S.Link
        onClick={() => impactOccurred('soft')}
        to="/shop"
        $isActive={isActive('/shop')}
      >
        <img
          src={ShopIcon}
          alt=""
        />
        {t('shop')}
      </S.Link>
      <S.Link
        onClick={() => impactOccurred('soft')}
        to="/rating"
        $isActive={isActive('/rating')}
      >
        <img
          src={RatingIcon}
          alt=""
        />
        {t('rating')}
      </S.Link>
      <S.Link
        onClick={() => impactOccurred('soft')}
        to="/profile"
        $isActive={isActive('/profile')}
      >
        <img
          src={ProfileIcon}
          alt=""
        />
        {t('profile')}
      </S.Link>
    </S.Footer>
  );
};

export default memo(Navigation);
