import styled from 'styled-components';

export const Modal = styled.div`
    position: fixed;
    z-index: 120;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const Body = styled.div`
    position: relative;
    margin: auto 12px;
    background-color: #0F0E16;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    border: 1px solid rgba(255,255,255,0.1);
    animation: ModalFadeIn 0.3s;

    @keyframes ModalFadeIn {
        0% {
            opacity: 0;
            transform: translate(0, 24px);
        }

        100% {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
`;

export const Backdrop = styled.button`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const Close = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Icon = styled.div`
    background: #2872EF;
    width: 74px;
    height: 74px;
    margin: -57px auto 10px;
    border-radius: 50%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Image = styled.img`
    display: block;
`;

export const Title = styled.div`
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
`;
