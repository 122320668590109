import { TItemCategory } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { sendRequest } from '@src/network';

type TRatingSliceState = {
  categories: TItemCategory[];
  loading: boolean;
}

export const itemCategorySlice = createAppSlice({
  name: 'itemCategory',
  initialState: {
    categories: [],
    loading: true,
  } as TRatingSliceState,
  reducers: create => ({
    fetchCategories: create.asyncThunk(
      async (_, { signal }) => sendRequest<TItemCategory[]>('/category', {
        signal,
      }),
      {
        pending: state => {
          state.loading = true;
          state.categories = [];
        },
        rejected: (state, action) => {
          console.log(action.error);
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.categories = action.payload || [];
        },
      },
    ),
  }),
  selectors: {
    selectCategories: state => state.categories,
    selectCategoriesLoading: state => state.loading,
  },
});

export const {
  fetchCategories,
} = itemCategorySlice.actions;

export const {
  selectCategories,
  selectCategoriesLoading,
} = itemCategorySlice.selectors;

export default itemCategorySlice.reducer;
