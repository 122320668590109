import styled from "styled-components";

export const Wrapper = styled.div`
    background-color: #0E0C14;
    padding: 20px 10px;
    border: 1px solid rgba(255,255,255,0.4);
    border-radius: 10px;
`;

export const Title = styled.div`
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
`;

export const SubTitle = styled.div`
    font-size: 14px;
    font-weight: 700;
    margin: 20px 0 10px;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    
    svg {
        display: block;
        max-width: 100%;
    }
`;

export const Button = styled.div`
    color: #fff;
    background-color: rgba(255,255,255,0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
    padding: 14px;
    border-radius: 10px;
    margin: 20px auto 0;
    width: 190px;
`;
