import styled, { css } from "styled-components";

export const Avatar = styled.div`
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
`;

export const Banner = styled.div`
    padding: 10px;
    border-radius: 10px;
    background-color: #0F0E16;
    border: 1px solid rgba(255,255,255,0.4);
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

export const BannerContent = styled.div`
    margin-left: 30px;
    margin-right: auto;
`;

export const BannerButton = styled.button`
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 24px;
    color: #fff;
    background-color: #2872EF;
`;

export const BannerReward = styled.div`
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    display: flex;
    align-items: center;
    gap: 4px;
`;
export const BannerText = styled.div`
    font-size: 16px;
    color: rgba(255,255,255,0.6);
`;

export const Player = styled.div`
    padding: 10px;
    border-radius: 10px;
    background-color: #2872EF;
    border: 1px solid rgba(255,255,255,0.4);
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

export const PlayerContent = styled.div`
    margin-left: 10px;
    margin-right: auto;
`;

export const PlayerName = styled.div`
    font-size: 14px;
`;

export const PlayerPlace = styled.div`
    font-size: 20px;
    font-weight: 700;
`;

export const TabWrapper = styled.div`
    padding: 10px;
    border-radius: 10px;
    background-color: #0F0E16;
    border: 1px solid rgba(255,255,255,0.4);
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
`;

type TTab = {
  $isActive?: boolean;
}

export const Tab = styled.button<TTab>`${({ $isActive }) => css`
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 10px;
    color: #fff;
    background-color: ${$isActive ? '#2872EF' : 'rgba(255,255,255,.1)'};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    
    &:disabled {
        color: rgba(255,255,255,.3);
    }
`}`;

export const RatingPlayer = styled.div`
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(255,255,255,0.1);
    border: 1px solid rgba(255,255,255,0.4);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

export const ModalContent = styled.div`
    max-height: 310px;
    overflow: auto;
    margin-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 54px;
    gap: 10px;
`;

export const Referral = styled.div`
    background-color: #222028;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;
`;

export const ReferralHeader = styled.div`
    display: flex;
    align-items: center;
`;

export const ReferralLevel = styled.div`
    font-size: 12px;
    color: rgba(255,255,255,.7);
    text-align: left;
    margin-top: 6px;
`;

