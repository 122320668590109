import * as React from 'react';
import * as S from './SVG.styled';
import { FC, memo } from 'react';
import { IconPaths } from '@src/svg/generated';

interface Props {
  name: string;
  className?: string;
  customColor?: string;
  height?: number;
  width?: number;
}

const SVG: FC<Props> = ({
  className,
  customColor,
  name,
  height,
  width,
}) => {
  if (IconPaths[name]) {
    return (
      <S.SVG
        innerHtml={IconPaths[name]}
        className={className}
        $customColor={customColor}
        height={height}
        width={width}
      />
    );
  }

  return null;
};

export default memo(SVG);
