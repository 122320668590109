import * as React from 'react';
import * as S from './Modal.styled';
import { FC, memo } from 'react';
import { useHapticFeedback } from '@common/hooks';
import SVG from '@ui/SVG/SVG';

export type TModalItem = {
  label: string;
  value?: string | React.JSX.Element;
  isMoney?: boolean;
}

type TProps = {
  title?: string;
  imageSrc?: string;
  children?: React.JSX.Element | React.JSX.Element[];
  closeCallback: () => void;
}

const Modal: FC<TProps> = ({
  title,
  imageSrc,
  children,
  closeCallback,
}) => {
  const [impactOccurred] = useHapticFeedback();

  return (
    <S.Modal>
      <S.Backdrop onClick={() => {
        impactOccurred('soft');
        closeCallback();
      }} />
      <S.Body>
        <S.Icon>
          {imageSrc && (
            <S.Image src={imageSrc} />
          )}
        </S.Icon>
        <S.Close onClick={() => {
          impactOccurred('soft');
          closeCallback();
        }}>
          <SVG name="close" />
        </S.Close>
        {title && (
          <S.Title>
            {title}
          </S.Title>
        )}
        {children}
      </S.Body>
    </S.Modal>
  );
};

export default memo(Modal);
