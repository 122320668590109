import * as React from 'react';
import * as S from './Quest.styled';
import { FC, memo } from 'react';
import { selectTasks } from '@src/redux/slices/taskSlice';
import { useSelector } from 'react-redux';
import QuestCard from '@components/Quests/QuestCard';
import Capital from "@layouts/Capital";

const Quests: FC = () => {
  const tasks = useSelector(selectTasks);

  return (
    <Capital>
      <S.TaskGrid>
        {tasks.map(task => (
          <QuestCard
            key={task.id}
            task={task}
          />
        ))}
      </S.TaskGrid>
    </Capital>
  );
};

export default memo(Quests);
