import * as React from 'react';
import { FC, memo, useCallback, useState } from 'react';
import * as S from './Friends.styled';
import { selectPhotoUrl, selectReferralLink, selectUsername } from '@src/redux/slices/playerSlice';
import { selectReferrals } from '@src/redux/slices/referralSlice';
import { useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import SVG from '@ui/SVG/SVG';
import Capital from "@layouts/Capital";
import ReferralImg from "@src/images/referral.png";
import { selectPlayers } from "@src/redux/slices/ratingSlice";
import Place1Img from '@src/images/place-1.png';
import Place2Img from '@src/images/place-2.png';
import Place3Img from '@src/images/place-3.png';
import Modal from "@ui/Modal/Modal";
import LetterIcon from "@src/images/letter.png";
import Button from "@ui/Button/Button";
import { ButtonType } from "@src/app/types";

const Friends: FC = () => {
  const [impactOccurred, notificationOccurred] = useHapticFeedback();
  const [modalOpen, setModalOpen] = useState(false);
  const playerReferralLink = useSelector(selectReferralLink);
  const referrals = useSelector(selectReferrals);
  const ratingPlayers = useSelector(selectPlayers);
  const photoUrl = useSelector(selectPhotoUrl);
  const userName = useSelector(selectUsername);

  const shareLink = useCallback(() => {
    impactOccurred('soft');
    const shareText = encodeURIComponent('Играй со мной в AVATARIA!'
      + '\n💸 5k монет в качестве первого подарка'
      + '\n🔥 25k монет, если у тебя есть Telegram Premium');
    const shareUrl = encodeURIComponent(`https://t.me/AvatariaNftBot/start?startapp=${playerReferralLink}`);
    const link = `https://t.me/share/url?url=${shareUrl}&text=${shareText}`;
    Telegram.WebApp.openTelegramLink(link);
  }, [impactOccurred, playerReferralLink]);

  const copyLink = useCallback(() => {
    impactOccurred('soft');
    const link = `https://t.me/AvatariaNftBot/start?startapp=${playerReferralLink}`;

    navigator.clipboard.writeText(link).then(() => {
      notificationOccurred('success');
    }, err => {
      notificationOccurred('error');
      Telegram.WebApp.showAlert(`Ошибка при копировании ссылки: ${err}`);
    });
  }, [impactOccurred, notificationOccurred, playerReferralLink]);

  const closeCallback = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <Capital>
        <S.Banner>
          <img src={ReferralImg} alt=""/>
          <S.BannerContent>
            <S.BannerReward>
              25 000
              <SVG name="coin" />
            </S.BannerReward>
            <S.BannerText>Короткий текст пояснения о бонусах</S.BannerText>
          </S.BannerContent>
          <S.BannerButton onClick={() => setModalOpen(true)}>Пригласить</S.BannerButton>
        </S.Banner>
        <S.Player>
          <S.Avatar>
            <img src={photoUrl} alt="" />
          </S.Avatar>
          <S.PlayerContent>
            <S.PlayerName>@{userName || 'unknown'}</S.PlayerName>
            <div></div>
          </S.PlayerContent>
          <S.PlayerPlace>#1</S.PlayerPlace>
        </S.Player>
        <S.TabWrapper>
          <S.Tab disabled>
            <SVG name="lock" customColor="rgba(255,255,255,.3)" />
            Друзья
          </S.Tab>
          <S.Tab $isActive>Весь мир</S.Tab>
          <S.Tab disabled>
            <SVG name="lock" customColor="rgba(255,255,255,.3)" />
            Сквады
          </S.Tab>
        </S.TabWrapper>
        <>
          {ratingPlayers.map((player, index) => (
            <S.RatingPlayer key={index}>
              <S.Avatar>
                <img src={player.photo_url} alt="" />
              </S.Avatar>
              <S.PlayerContent>
                <S.PlayerName>@{player.username || 'unknown'}</S.PlayerName>
                <div></div>
              </S.PlayerContent>
              <S.PlayerPlace>
                {index === 0 && <img src={Place1Img} alt=""/>}
                {index === 1 && <img src={Place2Img} alt=""/>}
                {index === 2 && <img src={Place3Img} alt=""/>}
                {index > 2 && `#${index}`}
              </S.PlayerPlace>
            </S.RatingPlayer>
          ))}
        </>
      </Capital>
      {modalOpen && (
        <Modal
          title="Рефералы"
          closeCallback={closeCallback}
          imageSrc={LetterIcon}
        >
          <S.ModalContent>
            {referrals.map((referral, index) => (
              <S.Referral key={index}>
                <S.ReferralHeader>
                  <S.Avatar>
                    <img src={referral.photo_url} alt="" />
                  </S.Avatar>
                  <S.PlayerContent>
                    <S.PlayerName>@{referral.username || 'unknown'}</S.PlayerName>
                    <S.ReferralLevel>Lvl {referral.level}</S.ReferralLevel>
                  </S.PlayerContent>
                </S.ReferralHeader>
              </S.Referral>
            ))}
          </S.ModalContent>
          <S.ButtonWrapper>
            <Button
              text="Пригласить"
              clickCallback={shareLink}
            />
            <Button
              type={ButtonType.BORDER}
              text={<SVG name="copy"/>}
              clickCallback={copyLink}
            />
          </S.ButtonWrapper>
        </Modal>
      )}
    </>
  );
};

export default memo(Friends);
