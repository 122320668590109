import { TPlayer, TTask } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { sendRequest } from '@src/network';

export enum TaskCategory {
  NEW = 'NEW',
  IN_PROCESS = 'IN_PROCESS',
  DONE = 'DONE',
}

type TTaskState = {
  tasks: TTask[];
  currentTask?: TTask;
  loading: boolean;
  completingTask: boolean;
}

export type TTaskComplete = {
  tasks: TTask[];
  player: TPlayer;
}

export const taskSlice = createAppSlice({
  name: 'task',
  initialState: {
    tasks: [],
    loading: true,
    completingTask: false,
  } as TTaskState,
  reducers: create => ({
    setCurrentTask: create.reducer<TTask | undefined>((state, action) => {
      state.currentTask = action.payload;
    }),
    fetchTasks: create.asyncThunk(
      async (_, { signal }) => sendRequest<TTask[]>('/task', {
        signal,
      }),
      {
        pending: state => {
          state.loading = true;
        },
        fulfilled: (state, action) => {
          if (action.payload) {
            state.tasks = action.payload;
          }
          state.loading = false;
        },
      },
    ),
    completeTask: create.asyncThunk(
      async (currentTask: TTask, { signal }) => sendRequest<TTaskComplete>('/task/complete', {
        signal,
        method: 'POST',
        body: {
          id: currentTask.id,
        },
        showToast: true,
      }),
      {
        pending: state => {
          state.completingTask = true;
        },
        rejected: state => {
          state.currentTask = undefined;
          state.completingTask = false;
        },
        fulfilled: (state, action) => {
          if (action.payload) {
            state.tasks = action.payload.tasks;
          }
          state.currentTask = undefined;
          state.completingTask = false;
        },
      },
    ),
  }),
  selectors: {
    selectTasks: state => state.tasks,
    selectTaskLoading: state => state.loading,
    selectCompletingTask: state => state.completingTask,
    selectCurrentTask: state => state.currentTask,
  },
});

export const {
  fetchTasks,
  setCurrentTask,
  completeTask,
} = taskSlice.actions;

export const {
  selectTasks,
  selectTaskLoading,
  selectCurrentTask,
  selectCompletingTask,
} = taskSlice.selectors;

export default taskSlice.reducer;
