import styled, { css } from 'styled-components';

export const LevelBar = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const Avatar = styled.div`
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
`;

export const Content = styled.div`
    flex: 1 1 auto;
`;

export const Name = styled.div`
    font-size: 14px;
    line-height: 1;
    margin-bottom: 5px;
`;

export const Progress = styled.div`
    height: 17px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

type TCurrentProgress = {
  $width: number;
}

export const CurrentProgress = styled.div<TCurrentProgress>`${({$width}) => css`
    width: ${$width}%;
    height: 100%;
    border-radius: 24px;
    background: linear-gradient(103.64deg, #EEBE14 -11.46%, #EE3814 183.02%);
    position: absolute;
    top: 0;
    left: 0;
`}`;

export const Level = styled.div`
    font-size: 12px;
    line-height: 1;
    color: #fff;
    position: relative;
`;
