import { TGem } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { sendRequest } from '@src/network';

type TGemSliceState = {
  gems: TGem[];
  loading: boolean;
}

export const gemSlice = createAppSlice({
  name: 'gem',
  initialState: {
    gems: [],
    loading: true,
  } as TGemSliceState,
  reducers: create => ({
    fetchGems: create.asyncThunk(
      async (_, { signal }) => sendRequest<TGem[]>(`/gem-shop`, {
        signal,
      }),
      {
        pending: state => {
          state.loading = true;
          state.gems = [];
        },
        rejected: (state, action) => {
          console.log(action.error);
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.gems = action.payload || [];
        },
      },
    ),
  }),
  selectors: {
    selectGems: state => state.gems,
    selectGemLoading: state => state.loading,
  },
});

export const {
  fetchGems,
} = gemSlice.actions;

export const {
  selectGems,
  selectGemLoading,
} = gemSlice.selectors;

export default gemSlice.reducer;
