import React, { FC, memo } from 'react';
import Capital from "@layouts/Capital";
import { useSelector } from "react-redux";
import { selectCategories } from "@src/redux/slices/itemCategorySlice";
import NftImage from "@src/images/nft.png";
import * as S from './Shop.styled';

const Shop: FC = () => {
  const categories = useSelector(selectCategories);

  return (
    <Capital>
      <S.Wrapper>
        <div>
          <img src={NftImage} alt="" style={{width: '100%', display: 'block'}}/>
        </div>
        <S.Skin>
          <S.SkinTitle>Кожа</S.SkinTitle>
          <button/>
          <button/>
          <button/>
          <button/>
          <button/>
          <button/>
          <button/>
        </S.Skin>
        <S.ItemWrapper>
          <S.CategoryGrid>
            {categories.map(category => (
              <S.Category>
                <img src={`https://api.avataria-nft.ru/images/category/${category.image}`} alt="" />
              </S.Category>
            ))}
          </S.CategoryGrid>
        </S.ItemWrapper>
      </S.Wrapper>
    </Capital>
  );
}

export default memo(Shop);
