import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

type TButton = {
  disabled: boolean;
}

export const Button = styled.button<TButton>`${({ disabled }) => css`
    width: 100%;
    font-size: 14px;
    line-height: 1;
    padding: 20px;
    color: ${disabled ? '#fff' : '#fff'};
    border-radius: 10px;
    pointer-events: ${disabled ? 'none' : 'auto'};
    background-color: ${disabled ? '#474747' : '#2872EF'};
`}`;

export const LinkButton = styled(Link)<TButton>`${({ disabled }) => css`
    width: 100%;
    font-size: 15px;
    line-height: 1;
    font-weight: 600;
    padding: 24px;
    color: ${disabled ? '#fff' : '#1F2C53'};
    border-radius: 10px;
    pointer-events: ${disabled ? 'none' : 'auto'};
    background-color: ${disabled ? '#474747' : '#feae03'};
    box-shadow: 2px 3px 10px 0 #00000059;
`}`;

export const TextButton = styled.button<TButton>`${({ disabled }) => css`
    display: block;
    font-size: 15px;
    line-height: 1;
    color: #feae03;
    opacity: ${disabled ? '0.5' : '1'};
    pointer-events: ${disabled ? 'none' : 'auto'};
`}`;

export const ButtonBordered = styled.button<TButton>`${({ disabled }) => css`
    width: 100%;
    font-size: 14px;
    line-height: 1;
    padding: 12px;
    color: ${disabled ? '#fff' : '#fff'};
    border-radius: 10px;
    pointer-events: ${disabled ? 'none' : 'auto'};
    background-color: transparent;
    border: 1px solid #939DAD;
    display: flex;
    align-items: center;
    justify-content: center;
`}`;
