import * as React from 'react';
import * as S from './Loader.styled';
import { FC, memo } from 'react';
import DotLoader from '@ui/DotLoader/DotLoader';

const Loader: FC = () => (
  <S.Loader>
    <p>Генерируем ваш NFT аватар</p>
    <S.Text>
      <DotLoader />
    </S.Text>
  </S.Loader>
);

export default memo(Loader);
