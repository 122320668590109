import styled from "styled-components";

export const Text = styled.div`
    font-size: 18px;
    font-weight: 400;
    color: rgba(255,255,255,.7);
    margin-bottom: 20px;
`;

export const Earn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    gap: 16px;
    font-size: 35px;
    line-height: 1;
    font-weight: 700;
`;
