import React, { FC, memo } from 'react';
import Capital from "@layouts/Capital";
import * as S from "./Nft.styled";
import SVG from "@ui/SVG/SVG";
import NftImage from '@src/images/nft.png';

const Nft: FC = () => (
  <Capital>
    <S.Wrapper>
      <S.Title>My NFT</S.Title>
      <img src={NftImage} alt="" style={{ width: '100%', display: 'block' }} />
      <S.SubTitle>Базовые атрибуты</S.SubTitle>
      <S.Grid>
        <SVG
          name="atribute"
        />
        <SVG
          name="atribute"
        />
        <SVG
          name="atribute"
        />
        <SVG
          name="atribute"
        />
        <SVG
          name="atribute"
        />
        <SVG
          name="atribute"
        />
      </S.Grid>
      <S.SubTitle>Дополнительные параметры</S.SubTitle>
      <S.Grid>
        <SVG
          name="atribute"
        />
        <SVG
          name="atribute"
        />
      </S.Grid>
      <S.Button>
        <SVG
          name="lock"
        />
        MINT
      </S.Button>
    </S.Wrapper>
  </Capital>
);

export default memo(Nft);
