import { TCard, TPlayer } from '@src/app/types';
import { createAppSlice } from '@common/hooks';
import { sendRequest } from '@src/network';

export enum CardTab {
  NEW = 'NEW',
  UPGRADE = 'UPGRADE',
  EXPIRE = 'EXPIRE',
}

type TCardState = {
  currentCard?: TCard;
  cards: TCard[];
  loading: boolean;
  buyingCard: boolean;
}

export type TBuyCard = {
  cards: TCard[];
  player: TPlayer;
}

type TFetchCategories = {
  cards: TCard[];
}

export const cardSlice = createAppSlice({
  name: 'card',
  initialState: {
    currentCard: undefined,
    cards: [],
    loading: true,
    buyingCard: false,
  } as TCardState,
  reducers: create => ({
    setCurrentCard: create.reducer<TCard | undefined>((state, action) => {
      state.currentCard = action.payload;
    }),
    fetchCards: create.asyncThunk(
      async (_, { signal }) => sendRequest<TFetchCategories>('/card', {
        signal,
      }),
      {
        pending: state => {
          state.loading = true;
        },
        fulfilled: (state, action) => {
          if (action.payload?.cards) {
            state.cards = action.payload.cards;
          }
          state.loading = false;
        },
      },
    ),
    buyCard: create.asyncThunk(
      async (buyingCard: TCard, { signal }) => sendRequest<TBuyCard>('/card/buy', {
        signal,
        method: 'POST',
        body: {
          id: buyingCard.id,
        },
        showToast: true,
      }),
      {
        pending: state => {
          state.buyingCard = true;
        },
        rejected: state => {
          state.currentCard = undefined;
          state.buyingCard = false;
        },
        fulfilled: (state, action) => {
          if (action.payload?.cards) {
            state.cards = action.payload.cards;
          }
          state.currentCard = undefined;
          state.buyingCard = false;
        },
      },
    ),
  }),
  selectors: {
    selectCurrentCard: state => state.currentCard,
    selectCards: state => state.cards,
    selectCardsLoading: state => state.loading,
    selectBuyingCard: state => state.buyingCard,
  },
});

export const {
  setCurrentCard,
  fetchCards,
  buyCard,
} = cardSlice.actions;

export const {
  selectCurrentCard,
  selectCards,
  selectCardsLoading,
  selectBuyingCard,
} = cardSlice.selectors;

export default cardSlice.reducer;
