import Timer from '@ui/Timer/Timer';
import styled, { css } from 'styled-components';

export const Income = styled.div`
    background-color: rgba(255,255,255,0.1);
    color: #fff;
    padding: 16px 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    backdrop-filter: blur(14px);
    margin-top: 10px;
    
    svg {
        margin: 0 2px;
    }
`;

export const CategoryGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-top: 30px;
`;

export const Card = styled.div`${() => css`
    border-radius: 10px;
    padding: 10px;
    background-color: rgba(255,255,255,0.1);
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid rgba(255,255,255,0.4);
    backdrop-filter: blur(14px);
`}`;

export const CardImage = styled.img`
    display: block;
`;

export const CardName = styled.div`
    font-size: 14px;
    line-height: 1.1;
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

export const CardIncomeLabel = styled.div`
    margin-top: 5px;
    font-size: 12px;
    line-height: 1;
    color: rgba(255,255,255,0.6);
    display: flex;
    align-items: center;
    
    svg {
        margin: 0 2px;
    }
`;

export const CardRequirement = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 600;
    border-radius: 16px;
    backdrop-filter: blur(1px);
`;

export const CardRequirementTimer = styled.div`
    margin-bottom: 12px;
`;

export const Button = styled.button`
    font-size: 14px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #fff;
    background-color: #2872EF;
    border-radius: 40px;
    padding: 8px 20px;
    
    svg {
        margin-top: -2px;
    }
`;
