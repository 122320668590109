import * as React from 'react';
import { FC, memo, useCallback } from 'react';
import { selectCurrentCard, setCurrentCard } from '@src/redux/slices/cardSlice';
import { useAppDispatch, useModalOpen, useTimer } from '@common/hooks';
import { useSelector } from 'react-redux';
import BuyButton from '@components/Investment/BuyButton';
import Modal from '@ui/Modal/Modal';

const CardModal: FC = () => {
  const dispatch = useAppDispatch();
  const currentCard = useSelector(selectCurrentCard);
  const expireTimer = useTimer(currentCard?.expired_at);
  useModalOpen(!!currentCard);

  const closeCallback = useCallback(() => {
    dispatch(setCurrentCard(undefined));
  }, [dispatch]);

  return currentCard ? (
    <Modal
      closeCallback={closeCallback}
      imageSrc={currentCard.image ? (
        `https://api.avataria-nft.ru/images/card/720/${currentCard.image}`
      ) : ''}
      title={currentCard.name}
    >
      {!(currentCard.expired_at && !expireTimer) ? (
        <BuyButton card={currentCard}/>
      ) : undefined}
    </Modal>
  ) : null;
};

export default memo(CardModal);
