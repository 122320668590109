import * as React from 'react';
import * as S from '@components/Investment/Investments.styled';
import { FC, memo } from 'react';
import { TCard } from '@src/app/types';
import { getHumanBalance } from '@common/number';
import { setCurrentCard } from '@src/redux/slices/cardSlice';
import { useAppDispatch, useHapticFeedback } from '@common/hooks';
import SVG from '@ui/SVG/SVG';
import CardImage from '@src/images/card.png';

type TProps = {
  card: TCard;
}

const Card: FC<TProps> = ({ card }) => {
  const dispatch = useAppDispatch();
  const [impactOccurred] = useHapticFeedback();

  return (
    <S.Card
      onClick={() => {
        impactOccurred('soft');
        dispatch(setCurrentCard(card));
      }}
    >
      <S.CardImage
        src={card.image ? `https://api.avataria-nft.ru/images/card/360/${card.image}` : CardImage}
      />
      <S.CardContent>
        <S.CardName>{card.name}</S.CardName>
        <S.CardIncomeLabel>
          +{getHumanBalance(card.income)}
          <SVG name="coin" width={12} height={12} />
          /час
        </S.CardIncomeLabel>
      </S.CardContent>
      <S.Button>
        <SVG name="coin-white" />
        {card.price}
      </S.Button>
    </S.Card>
  );
};

export default memo(Card);
