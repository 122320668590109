import { asyncThunkCreator, buildCreateSlice } from '@reduxjs/toolkit';
import { setModalCloseStyles, setModalOpenStyles } from '@common/style';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { AppDispatch } from '../redux/store';

export const createAppSlice = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

export const useAppDispatch = (): ReturnType<typeof useDispatch<AppDispatch>> => useDispatch<AppDispatch>();

export type ImpactOccurredFunction = (
  style: 'light' | 'medium' | 'heavy' | 'rigid' | 'soft',
) => void;

export type NotificationOccurredFunction = (
  type: 'error' | 'success' | 'warning',
) => void;

export type SelectionChangedFunction = () => void;

export const useHapticFeedback = (): readonly [
  ImpactOccurredFunction,
  NotificationOccurredFunction,
  SelectionChangedFunction,
] => {
  const { WebApp } = Telegram;
  const HapticFeedback = WebApp?.HapticFeedback;

  const impactOccurred: ImpactOccurredFunction = useCallback(
    (...args) => HapticFeedback?.impactOccurred(...args),
    [HapticFeedback],
  );
  const notificationOccurred: NotificationOccurredFunction = useCallback(
    (...args) => HapticFeedback?.notificationOccurred(...args),
    [HapticFeedback],
  );
  const selectionChanged: SelectionChangedFunction = useCallback(
    (...args) => HapticFeedback?.selectionChanged(...args),
    [HapticFeedback],
  );

  return [impactOccurred, notificationOccurred, selectionChanged] as const;
};

export const useTimer = (deadline?: number): string => {
  const currentTime = new Date().getTime() / 1000;
  const [time, setTime] = useState(deadline && deadline > currentTime ? deadline - currentTime : 0);
  const timerID = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setTime(deadline && deadline > new Date().getTime() / 1000 ? deadline - new Date().getTime() / 1000 : 0);
  }, [deadline]);

  useEffect(() => {
    if (time > 0) {
      timerID.current = setInterval(() => {
        setTime(time - 1);
      }, 1000);
    }

    return () => {
      if (timerID.current) {
        clearInterval(timerID.current);
      }
    };
  }, [time]);

  return useMemo(() => {
    if (time > 0) {
      let hours: number|string = Math.floor(time / 3600);
      let minutes: number|string = Math.floor((time % 3600) / 60);
      let seconds: number|string = Math.floor(time % 60);
      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      return `${hours}:${minutes}:${seconds}`;
    }

    return '';
  }, [time]);
};

export const useModalOpen = (trigger: boolean): void => {
  useEffect(() => {
    if (trigger) {
      setModalOpenStyles();
    } else {
      setModalCloseStyles();
    }

    return () => {
      setModalCloseStyles();
    };
  }, [trigger]);
};
