import * as React from 'react';
import * as S from '@components/Investment/Investments.styled';
import { CardTab, selectCards } from '@src/redux/slices/cardSlice';
import { FC, memo } from 'react';
import { useHapticFeedback } from '@common/hooks';
import { useSelector } from 'react-redux';
import Capital from '@layouts/Capital';
import Card from '@components/Investment/Card';
import CardModal from '@components/Investment/CardModal';
import SVG from "@ui/SVG/SVG";
import { selectEarnPassive } from "@src/redux/slices/playerSlice";

const Investments: FC = () => {
  const [impactOccurred] = useHapticFeedback();
  const cards = useSelector(selectCards);
  const passiveIncome = useSelector(selectEarnPassive);

  return (
    <Capital>
      <>
        <S.Income>
          Пассивный доход +{passiveIncome}
          <SVG name="coin" />
          /час
        </S.Income>
        <S.CategoryGrid>
          {cards.map(card => (
            <Card
              key={card.id}
              card={card}
            />
          ))}
        </S.CategoryGrid>
        <CardModal />
      </>
    </Capital>
  );
};

export default memo(Investments);
