import styled from "styled-components";

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 66px;
    gap: 10px;
`;

export const Skin = styled.div`
    background-color: #0F0E16;
    border-radius: 10px;
    border: 1px solid rgba(255,255,255,0.4);
    padding: 10px;
`;

export const SkinTitle = styled.div`
    font-size: 14px;
    line-height: 1;
    margin-bottom: 10px;
    text-align: center;
`;

export const ItemWrapper = styled.div`
    background-color: #0F0E16;
    border-radius: 10px;
    border: 1px solid rgba(255,255,255,0.4);
    padding: 10px;
    grid-column: 1/-1;
`;

export const Category = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
`;

export const CategoryGrid = styled.div`
    display: flex;
`;
